<template>

  <header class="relative w-full flex flex-wrap items-center justify-between py-3 bg-gray-100 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg mb-10">
    <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
      <div class="container-fluid">
        <div class="text-4xl title-font font-medium text-gray-900">begauss
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title:String
  }
};
</script>

<style scoped></style>
