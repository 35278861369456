<template>
  <footer class="text-center lg:text-left">
    <div class="text-center p-4">
      © {{ new Date().getFullYear() }} :
      <span>begauss.ru - генератор арифметических примеров</span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped></style>
